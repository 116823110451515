<template>
  <EndTestBackground :testType="2">
    <b-container class="start-effecr-evaluation">
      <b-row class="text-spacing">
        <b-col
          ><span class="text-green"
            >{{ userInfo.studentName }}同学，</span
          >你好！</b-col
        >
      </b-row> 
      <b-row class="text-spacing">
        <b-col
          >恭喜你完成<span class="text-green"
            >中考英语冲刺{{
              moduleName(this.$route.query.module)
            }}的靶向训练</span
          >，现在请做一个<span class="text-green">效果测评</span
          >吧，看看你的学习效果！</b-col
        >
      </b-row>
      <b-row class="begin-button">
        <b-col>
          <GlobalButton
            buttonType="round"
            buttonText="开始测评"
            class="big-end-form-btn"
            @afterClick="startEvaluation"
          ></GlobalButton>
        </b-col>
      </b-row>
    </b-container>
  </EndTestBackground>
</template>

<script>
import EndTestBackground from "@/components/backgroung/end-test-background.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    EndTestBackground,
    GlobalButton,
  },
  computed:{
    ...mapState(["userInfo"]),
  },
  props:{
    studentName:{
      default:''
    }
  },
  methods: {
    startEvaluation() {
      this.$emit("startEvaluation");
      // this.$router.replace({
      //   name: "BigModuleEffectEvaluation",
      //   query: {
      //     module: 2,
      //   },
      // });
    },
    moduleName(moduleId) {
      if (moduleId == "1") {
        return "扫雷尖兵词汇部分";
      } else {
        return "沙漠行动";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.start-effecr-evaluation {
  min-height: 200px;
  padding: 1rem 0 6rem 0;
}
.text-green {
  color: $text-green;
}
.text-spacing {
  margin-bottom: 4%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}
.begin-button {
  padding-top: 2rem;
}
</style>