<template>
  <StartEffectEvaluation
    @startEvaluation="startEvaluation"
  ></StartEffectEvaluation>
</template>

<script>
import StartEffectEvaluation from "@/components/summary-page/start-effect-evaluation.vue";
import {getVocabularyFinish} from "@/api/english-small"
export default {
  data(){
    return{
      finishPage:''
    }
  },
  components: {
    StartEffectEvaluation,
  },
  created(){
    this.getPageData()
  },
  methods: {
    getPageData(){
       getVocabularyFinish({}).then((res) => {
        console.log("res", res);
        this.finishPage = res.data.result;
      });
    },
    startEvaluation() {
      this.$router.replace({ 
        name: "SmallModuleTest",
        query: {
          // module: 1,
          examId: this.finishPage.examId,
          sprintId: 2,
          text: 2,
          stage:3
        },
      });
    },
  },
};
</script>

<style>
</style>